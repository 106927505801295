import React, { Fragment } from 'react';

import Contact from '../components/contact-us';

export default ()=> {

  return(
    <Fragment>
      <Contact />
    </Fragment>
  )
}